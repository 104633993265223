<template>
  <div class="list-info" id="watermark">
    <top-bar :title="'场所管理'" :left="true"></top-bar>
    <div class="search">
      <input v-model="dataForm.searchValue" type="search" value="搜索" placeholder="搜索" class="search-content" @keyup.enter="onSearch">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
    <div class="addBtn" @click="$router.push('/place-info')">
      <img src="@/assets/img/add.png" alt="">
    </div>
    <div class="filterBar">
      <van-row>
        <van-col span="6">
          <p @click="show.orgShow = !show.orgShow" :style="{ color: show.orgShow ? '#387FF5':'#666666' }">
            {{ belongOrgTitle }}
            <img :src="require(`@/assets/img/${show.orgShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="6">
          <p @click="show.subareaShow = !show.subareaShow" :style="{ color: show.subareaShow ? '#387FF5':'#666666' }">
            {{ belongSubareaTitle }}
            <img :src="require(`@/assets/img/${show.subareaShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="6">
          <p @click="show.gridShow = !show.gridShow" :style="{ color: show.gridShow ? '#387FF5':'#666666' }">
            {{ belongGridTitle }}
            <img :src="require(`@/assets/img/${show.gridShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="6">
          <p @click="show.typeShow = !show.typeShow" :style="{ color: show.typeShow ? '#387FF5':'#666666' }">
            {{ placeTypeTitle }}
            <img :src="require(`@/assets/img/${show.typeShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
      </van-row>
    </div>
    <van-popup
      v-model="show.orgShow"
      position="bottom">
      <van-picker
          title="所属机构"
          show-toolbar
          :columns="list.orgList"
          value-key="label"
          @confirm="orgConfirm"
          @cancel="show.orgShow = false"
      />
    </van-popup>

    <van-popup
      v-model="show.subareaShow"
      position="bottom">
      <van-picker
        title="所属小区"
        show-toolbar
        :columns="list.subareaList"
        value-key="label"
        @confirm="subareaConfirm"
        @cancel="show.subareaShow = false"
      />
    </van-popup>
    <grid-select v-show="show.gridShow" :list="list.gridList" @selectLabel="selectGrid" ref="gridSelect"></grid-select>
    <van-popup
      v-model="show.typeShow"
      position="bottom">
      <van-cascader
        :field-names="fieldNames"
        v-model="dataForm.placeType"
        title="请选择"
        :options="list.placeTypeList"
        @close="show.typeShow = false"
        @change="typeConfirm"
      />
    </van-popup>

    <div class="cont" v-show="!show.gridShow">
      <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
          <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;条数据</div>
          <div class="list-item" v-for="item in dataList" :key="item.id" @click="selectInfo(item.id)">
            <div class="title">
              <div class="title-left">
                {{item.contact + ' | ' + item.contactMobile}}
              </div>
              <div class="title-right">
                <a :href="'tel:'+item.contactMobile" @click.stop=""><img src="@/assets/img/phone.png" alt="" class="tel"/></a>
              </div>
            </div>
            <div class="content">
              <van-image :src="item.url||require('@/assets/img/flower.png')" class="content-img">
                <template slot="error">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
                <template slot="loading">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
              </van-image>
              <div class="content-text">
                <div class="top-text">{{item.name}}</div>
                <div class="right-text" style="right:0" v-if="item.gridId !== 0 && item.gridId !== null">{{item.gridName}}</div>
                <div class="right-text" style="right:0" v-else>{{item.community == 0 ? item.streetName : item.communityName}}</div>
                <div class="bottom-text">{{item.address}}</div>
              </div>
              <van-icon name="arrow" color="#666" class="bottom-icon"/>
            </div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";

import {getbelongGrid, getDictTree, getbelongSubArea, getBelongCommunity,getMiniGrid} from "@/utils/common";
import {getImageStream} from "@/utils/index";
import GridSelect from '../userRes/grid-select.vue'
import Vue from "vue";
export default {
  components: {
    topBar,
    GridSelect
  },
  created() {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    this.dataForm.orgId = this.$orgId;
    let orgId = this.$globalData.userInfo.orgId
    let that = this;
    getBelongCommunity(this.$orgId, -1, (communityList) => {
      that.list.orgList = communityList
      that.list.orgList.unshift({
        label: '本街道',
        value: Number(orgId)
      })
    })
    getDictTree({code: "placeType"}, function (e) {
      that.list.placeTypeList = that.list.placeTypeList.concat(e);
    });
    getMiniGrid({community:this.$orgId}, function (list) {
      that.list.gridList = list
    })
    this.init();
  },
  data() {
    return {
      loading: false,
      pullLoading: false,
      finished: false,
      totalCount: 0,
      dataList: [],
      orgStr:'所属机构',
      subareaStr:'所属小区',
      placeTypeStr:'场所类型',
      fieldNames: { text: "label", value: "value", children: "children" },
      dataForm: {
        page: 0,
        limit: 5,
        subarea: "",
        placeType: "",
        houseType: "",
        searchValue: "",
        orgId: "30",
        gridId: '',
        miniGridId: "",
        // token:
        //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMDQwMTkzIiwiaWF0IjoxNjIyNzE0MjY3LCJleHAiOjE2MjMzMTkwNjd9.QeU4nOWTo6ARTR3ZQ_n_ONHif_m7GahavOjKUergTwrtjEysCpmoAObS1qUHlruJ0JVl4hACYVWENWEDif6Ekg",
      },
      list: {
        orgList: [{ label: "所有", value: "0" }],
        subareaList: [{ label: "所有", value: "0" }],
        placeTypeList: [{ label: "所有", value: "0" }],
        placeHouseTypeList: [],
        gridList: [],
      },
      show: {
        orgShow: false,
        subareaShow: false,
        gridShow: false,
        typeShow: false,
      },
      belongOrgTitle: "本街道",
      belongSubareaTitle: "所属小区",
      belongGridTitle: "所属网格",
      placeTypeTitle: "场所类型",
      houseTypeTitle: "房屋类型",
    };
  },
  methods: {
    init() {
      this.dataForm.page = 0;
      this.dataList = [];
      this.getDataList();
    },
    selectInfo(id) {
      this.$router.push({ path: "/place-info?id=" + id });
    },
    onSearch() {
      this.init();
      console.log(val);
    },
    onCancel() {
      this.dataForm.searchValue = "";
      this.init();
      console.log("取消");
    },
    onPullDownRefresh(e) {
      this.finished=false
      this.dataList = []
      this.dataForm.page = 0;
      this.getDataList()
    },
    goAdd() {
      this.$router.push("/place-info");
    },
    // 所属机构
    orgConfirm(value, index) {
      this.dataForm.subarea = "";
      this.belongSubareaTitle='所属小区'
      if (value.value == "0") {
        this.dataForm.orgId = "";
        this.belongOrgTitle='本街道'
      } else {
        this.dataForm.orgId = value.value;
        this.belongOrgTitle = value.label;
        getBelongCommunity(value.value, 0, (communityList) => {
          this.list.subareaList = communityList
        })
      }
      this.show.orgShow = false;
      this.init();
    },
    // 所属小区
    subareaConfirm(value, index) {
      if (value.value == "0") {
        this.dataForm.subarea = "";
        this.belongSubareaTitle='所属小区'
      } else {
        this.dataForm.subarea = value.value;
        this.belongSubareaTitle = value.label;
      }
      this.show.subareaShow = false;
      this.init();
    },
    selectGrid (e) {
      this.dataForm.gridId = ''
      this.dataForm.miniGridId = ''
      const {selectList, labelCondition, depth} = e
      if (depth == 1) {
        this.dataForm.gridId = selectList
      } else {
        this.dataForm.miniGridId = selectList
      }
      this.gridStr = '所属网格'
      this.dataForm.page = 0;
      this.dataList = [];
      this.getDataList();
      this.show.gridShow = false
    },
    typeConfirm({ selectedOptions }) {
      let value = selectedOptions[selectedOptions.length - 1];
      if (value.value == "0") {
        this.dataForm.placeType = "";
        this.placeTypeTitle = "场所类型";
      } else if (value) {
        this.placeTypeTitle = value.label;
        this.dataForm.placeType = value.value;
      }
      if (!value.children || value.children.length == 0) {
        this.show.typeShow = false;
        this.init();
      }
    },
    getDataList() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.dataForm.page++;
      this.$http({
        url: this.$http.adornUrl("/wxapp/place/info/list"),
        method: "post",
        params: this.$http.adornParams(this.dataForm),
      }).then(
        ({ data }) => {
          this.$toast.clear();
          if (data.code == 0) {
            this.totalCount = data.page.totalCount;
            if (this.dataList.length == data.page.totalCount) {
              this.$toast.clear();
              this.finished = true;
              return;
            }
            if (this.dataForm.page == 1 && data.page.list.length == 0) {
              this.dataList = [];
            }
            console.log("place---list");
            console.log(data);
            data.page.list.forEach((item) => {
              if (item.imgUrl && item.imgUrl.length != 0) {
                let urls = item.imgUrl.split(",");
                let url = getImageStream(urls[0]);
                item["url"] = url;
              }
            });
            this.dataList = this.dataList.concat(data.page.list);
          }else {
            this.$toast.fail(data.msg)
          }
          // 加载状态结束
          this.loading = false
          this.pullLoading = false
        },
        (err) => {
          this.loading = false
          this.pullLoading = false
          this.$toast.clear()
        }
      );
    },
  },
};
</script>
